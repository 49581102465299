import { PromotionalEventType } from '@faire/web-api--source/indigofair/common/PromotionalEventType';
import { IBrandPromo } from '@faire/web-api--source/indigofair/data/IBrandPromo';

export namespace IBrandPromoCollection {
  /**
   * The tags are out of order. All tag numbers must be unique. When adding fields, also update the field below and the tag number.
   * See https://www.notion.so/faire/Protocol-Buffers-Protos-ba527d2f70c34ab983b8d0417c6a56ac#6a792ceb9d37498795a117e712b9e7e7 for details.
   * The last tag added was `productBasedCollectionToken`. The next tag number is 21.
   */
  export enum Type {
    /**
     * Catalog-level promotions based on certain tiers of per-order spending. For example,
     * if you spend more than $50 on a particular brand in a single order, you will get a
     * $5 discount. It is possible to have multiple tiers based on price. However, the
     * important distinction here is that these promos are Catalog-level.
     * TODO(noah): consider renaming this CATALOG instead of TIERED
     */
    TIERED = 'TIERED',
    /**
     * Used for abandoned cart promotion experiment. No longer in use.
     * TODO(noah): consider actually cleaning this up since it has not been used for almost a year (#32836)
     * @deprecated
     */
    RETAILER_SPECIFIC = 'RETAILER_SPECIFIC',
    /**
     * Product-level promotion where promotions are attached to a given product.
     */
    PRODUCT = 'PRODUCT',
  }

  export enum Program {
    PROGRAM_UNKNOWN = 'PROGRAM_UNKNOWN',
    /**
     * Promotion created and funded by the brand via the Brand Promotions portal.
     */
    BRAND_PROMOTION = 'BRAND_PROMOTION',
    /**
     * Promotion program based on loyalty relations between a single brand and retailer.
     * See the MVP PRD for more details:
     * https://www.notion.so/faire/MVP-Volume-discounts-a802a4dc20eb4c869560b9faeaeedc64 *&#8205;/
     * @deprecated
     */
    RETAILER_BRAND_LOYALTY = 'RETAILER_BRAND_LOYALTY',
    /**
     * Sponsored Discount for Opening Orders for Faire Direct Rewards Brands
     * https://www.notion.so/faire/Faire-Direct-Rewards-Discount-FDR-Discount-8dc513866bf243a69c996b8c7ca378d9
     * @deprecated
     */
    FAIRE_DIRECT_REWARDS = 'FAIRE_DIRECT_REWARDS',
  }

  export enum State {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    CANCELED = 'CANCELED',
    PENDING = 'PENDING',
    DELETED = 'DELETED',
  }

  export enum RetailerAudience {
    /**
     * The promotion will only be shown to retailers who have not yet had one complete order with this brand.
     */
    NEW_RETAILERS = 'NEW_RETAILERS',
    /**
     * The promotion will only be shown to retailers that have had at least one un-cancelled order with this brand.
     */
    EXISTING_RETAILERS = 'EXISTING_RETAILERS',
    /**
     * The promotion will be shown to all retailers.
     */
    ALL_RETAILERS = 'ALL_RETAILERS',
    /**
     * Used for abandoned cart promotion experiment. No longer in use.
     * TODO(noah): consider actually cleaning this up since it has not been used for almost a year (#32836)
     * @deprecated
     */
    SPECIFIC_RETAILER = 'SPECIFIC_RETAILER',
  }

  export enum ProductShipAvailability {
    ALL_PRODUCTS = 'ALL_PRODUCTS',
    /**
     * Only immediate products will be counted towards minimum spend requirement and will be discounted
     * Use this instead of ONLY_IMMEDIATE_PRODUCTS_DISCOUNTED if you don't want preorder items to be counted for
     * minimum spend requirement
     */
    ONLY_IMMEDIATE_PRODUCTS = 'ONLY_IMMEDIATE_PRODUCTS',
    /**
     * All products are counted towards minimum spend requirement, but only immediate products will be discounted
     */
    ONLY_IMMEDIATE_PRODUCTS_DISCOUNTED = 'ONLY_IMMEDIATE_PRODUCTS_DISCOUNTED',
  }

  /**
   * Return an instance of IBrandPromoCollection with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IBrandPromoCollection>
  ): IBrandPromoCollection =>
    ({
      promos: [],
      ...(partial ?? {}),
    }) as IBrandPromoCollection;
}

export interface IBrandPromoCollection {
  token: string | undefined;
  brand_token: string | undefined;
  type: keyof typeof IBrandPromoCollection.Type | undefined;
  program: keyof typeof IBrandPromoCollection.Program | undefined;
  state: keyof typeof IBrandPromoCollection.State | undefined;
  code: string | undefined;
  start_at: number | undefined;
  end_at: number | undefined;
  retailer_audience:
    | keyof typeof IBrandPromoCollection.RetailerAudience
    | undefined;
  product_ship_availability:
    | keyof typeof IBrandPromoCollection.ProductShipAvailability
    | undefined;
  promos: Array<IBrandPromo>;
  /**
   * @deprecated
   */
  retailer_visits: number | undefined;
  updated_at: number | undefined;
  /**
   * Used to display discount matching during promotional events.
   */
  description: string | undefined;
  /**
   * It will be active when market promotion creation starts
   */
  promotional_event_type: keyof typeof PromotionalEventType | undefined;
  promotional_event_key: string | undefined;
  /**
   * @protobufOption (faire.protobuf.validation) = TIMESTAMP_MILLIS
   */
  scheduled_start_at: number | undefined;
  /**
   * @protobufOption (faire.protobuf.validation) = TIMESTAMP_MILLIS
   */
  scheduled_end_at: number | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = PRODUCT_BASED_COLLECTION
   */
  product_based_collection_token: string | undefined;
  order_count: number | undefined;
}
