/* Various market surfaces are customized for each of these retailer segments.
 * Market components should use these definitions to ensure consistency across all
 * surfaces instead of using RetailerStore or InsiderMembershipInformation directly.
 * These segment definitions are mutually exclusive.
 */
export enum RetailerSegment {
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
  INSIDER_ELIGIBLE = "INSIDER_ELIGIBLE",
  INSIDER_CHURNED = "INSIDER_CHURNED", // Canceled, paused or unpaid
  INSIDER_ENROLLED = "INSIDER_ENROLLED", // Trialing, active
  NOT_INSIDER = "NOT_INSIDER", // Not eligible for Insider
}

export enum MarketState {
  EARLY_ACCESS = "EARLY_ACCESS",
  MAIN_EVENT = "MAIN_EVENT",
  DISABLED = "DISABLED",
}

export const PT_TIMEZONE = "America/Los_Angeles";
