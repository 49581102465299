import { ILineItemStyle } from "@faire/retailer-visitor-shared/components/getLineItemColorByStyleAndSurface";
import { getNextOrActiveEvent } from "@faire/retailer-visitor-shared/serialized-data/getNextOrActiveEvent";
import { clock } from "@faire/web--source/common/clock";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { ITheme } from "@faire/web--source/slate/Theme";
import { FaireMarketTheme } from "@faire/web--source/slate/Theme/EventsTheme";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";
import { PromotionalEventType } from "@faire/web-api--source/indigofair/common/PromotionalEventType";
import { ICurrentPromotionalEventResponse } from "@faire/web-api--source/indigofair/data/ICurrentPromotionalEventResponse";
import { toZonedTime } from "date-fns-tz";
import { isAfter } from "date-fns/isAfter";

import PromotionalEventGroup = ICurrentPromotionalEventResponse.IPromotionalEvent.PromotionalEventGroup;

type EventType = keyof typeof PromotionalEventType;

/**
 * @deprecated prefer to use `useEventsStore` or `withStores` instead
 */
export class EventsStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(EventsStore);

  get nextOrActive(): ICurrentPromotionalEventResponse | undefined {
    return getNextOrActiveEvent();
  }

  get isMarketEvent(): boolean {
    return (
      this.nextOrActive?.event?.event_group === PromotionalEventGroup.MARKET
    );
  }

  get eventLineItemStyle(): ILineItemStyle {
    return this.isMarketEvent
      ? ILineItemStyle.MARKET
      : ILineItemStyle.FAIRE_FASHION_WEEK_DISCOUNT;
  }

  get nextOrActiveEventTheme(): ITheme {
    switch (this.nextOrActive?.event?.event_group) {
      case PromotionalEventGroup.MARKET:
      default:
        return FaireMarketTheme;
    }
  }

  eventData = (
    eventType: EventType
  ): ICurrentPromotionalEventResponse | undefined => {
    return this.nextOrActive?.event?.event_type === eventType
      ? this.nextOrActive
      : undefined;
  };

  isEventExpired = (eventType: EventType): boolean => {
    const eventData = this.nextOrActive;
    if (!eventData || eventData?.event?.event_type !== eventType) {
      return true;
    }
    const endAt = eventData.end_at;
    if (endAt === undefined) {
      // assuming an event with undefined end date always live
      return false;
    }
    return isAfter(clock.now(), endAt);
  };

  static unsnapFromPT = (timeUtc: number): number => {
    return toZonedTime(timeUtc, PT_TIMEZONE).valueOf();
  };
}
export const useEventsStore = createStoreHook(EventsStore);

const PT_TIMEZONE = "America/Los_Angeles";
