import { ITimestampRange } from "@faire/web-api--source/indigofair/data/ITimestampRange";
import { ITimestamp } from "@faire/web-api--source/indigofair/rfc3339/ITimestamp";
import { parseISO } from "date-fns/parseISO";

/**
 * Converts the given timestamp object, millis number or ISO8601 string into a JS millis equivalent.
 */
export const toMillis = (
  timestamp: Date | ITimestamp | number | string | undefined
): number | undefined => {
  if (typeof timestamp === "number") {
    return timestamp;
  } else if (typeof timestamp === "string") {
    return parseISO(timestamp).getTime();
  } else if (timestamp instanceof Date) {
    return timestamp.getTime();
  }
  return (
    (timestamp?.seconds && Math.floor(timestamp?.seconds * 1000)) ??
    (timestamp?.nanos && Math.floor(timestamp?.nanos / 1000000))
  );
};

/**
 * Converts the given timestamp object into a JS millis equivalent.
 *  This requires the timestamp to have both seconds and nanos defined.
 * @param timestamp
 */
export const timestampToMillis = (
  timestamp: ITimestamp | undefined
): number | undefined => {
  if (timestamp?.seconds === undefined || timestamp?.nanos === undefined) {
    return undefined;
  }
  const nanos = timestamp.nanos;
  const millisWithoutNanos = timestamp.seconds * 1000;
  const millisWithNanos = millisWithoutNanos + Math.floor(nanos / 1000 / 1000);
  return millisWithNanos;
};

/**
 * Converts the given timestamp object, millis number or ISO8601 string into a JS Date.
 */
export const toDate = (
  timestamp: Date | ITimestamp | number | string | undefined
): Date | undefined => {
  const millis = toMillis(timestamp);
  if (millis === undefined) {
    return undefined;
  }
  return new Date(millis);
};

/**
 * Converts the given Date, ISO8601 string or millis number value to an ITimestamp equivalent.
 * @returns equivalent ITimestamp
 */
export function toTimestamp(
  timestamp: Date | number | string | ITimestamp
): ITimestamp;
export function toTimestamp(
  timestamp: Date | number | string | ITimestamp | undefined
): ITimestamp | undefined;

export function toTimestamp(
  timestamp: Date | number | string | ITimestamp | undefined
): ITimestamp | undefined {
  if (typeof timestamp === "undefined") {
    return undefined;
  }
  const millis = toMillis(timestamp);
  if (typeof millis === "undefined") {
    return undefined;
  }
  return ITimestamp.build({
    nanos: millis * 1000000,
  });
}

/**
 * Converts the given Date or millis number value to an ITimestamp equivalent.
 *   This conforms to the `real` timestamp format, which uses both seconds and nanos, as accepted by BE.
 * @param dateOrNumber
 */
export const toRealTimestamp = (dateOrNumber: Date | number): ITimestamp => {
  const millis =
    dateOrNumber instanceof Date ? dateOrNumber.getTime() : dateOrNumber;
  return ITimestamp.build({
    seconds: Math.floor(millis / 1000),
    nanos: (millis % 1000) * 1000000,
  });
};

/**
 * @deprecated use toTimestamp or toRealTimestamp
 * TODO(Zimin): remove this function
 */
export function toTimestampWithSeconds(
  timestamp: Date | number | string | ITimestamp
): ITimestamp;
export function toTimestampWithSeconds(
  timestamp: Date | number | string | ITimestamp | undefined
): ITimestamp | undefined;

export function toTimestampWithSeconds(
  timestamp: Date | number | string | ITimestamp | undefined
): ITimestamp | undefined {
  if (timestamp instanceof Date || typeof timestamp === "number") {
    return toRealTimestamp(timestamp);
  }
  return toTimestamp(timestamp);
}

/**
 * Converts the given Date(s) or millis number(s) values to an ITimestampRange equivalent.
 * @param startAt Date or millis-since-epoch number value
 * @param endAt Date or millis-since-epoch number value
 * @returns equivalent ITimestampRange, with start_at and end_at (using `nanos`)
 */
export function toTimestampRange(
  startAt: Date | number,
  endAt: Date | number | undefined
): ITimestampRange;

export function toTimestampRange(
  startAt: Date | number | undefined,
  endAt: Date | number
): ITimestampRange;

export function toTimestampRange(
  startAt: undefined,
  endAt: undefined
): undefined;

export function toTimestampRange(
  startAt: Date | number | undefined,
  endAt: Date | number | undefined
): ITimestampRange | undefined {
  if (startAt === undefined && endAt === undefined) {
    return undefined;
  }
  return ITimestampRange.build({
    start_at: toTimestamp(startAt),
    end_at: toTimestamp(endAt),
  });
}
