import { parseToArray, parseToNumber } from '@faire/web-api--source/parseUtils';
import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';

export type QueryParameters = {
  page: number | undefined;
  query: string | undefined;
  filters: Array<string> | undefined;
  brand: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    parsed['page'] = parseToNumber(rawParsed['page'] as string);

    parsed['filters'] = parseToArray<string>(rawParsed['filters']);

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/markets/{event_type}/all-specials"
 *
 * Product Area: PROMOTIONAL_EVENTS
 */
export const route = '/markets/:eventType/all-specials';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/markets/{event_type}/all-specials',
  productArea: 'PROMOTIONAL_EVENTS',
  pod: 'FAIRE_POD_UPMARKET_TOOLING',
  pillar: 'FAIRE_PILLAR_RETAILER_TOOLS_AND_PROGRAMS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(
  eventType: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    eventType,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  eventType: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return faireBaseUrl(3000) + path(eventType, queryParams);
};

export type PathParameters = {
  eventType: string;
};

export default path;
